<template>
	<header>
		<div class="head show_pc">
			<router-link to="/" class="head-logo-box">
				<!-- <img src="../../static/images/bclogo.png" class="bancanLogo"> -->
				<img src="../../static/images/logo.png" class="headLogo1" style="height: 56.54px;">
			</router-link>
			<!-- <router-link to="/userCenter" class="head-login" @click="showLoginBox">登录/注册</router-link> -->

			<div class="head-menu">
				<el-menu :default-active="headActiveIndex" router class="el-menu-base" mode="horizontal"
					@select="handleSelect">
					<el-menu-item index="HomeView" route="/">
						<div class="head-mune-title">首页</div>
					</el-menu-item>
					<el-menu-item index="aboutserve" route="/aboutserve">
						<div class="head-mune-title">服务体系</div>
					</el-menu-item>
					<el-menu-item index="aboutotop" route="/aboutotop">
						<div class="head-mune-title">平台介绍</div>
					</el-menu-item>
					<el-menu-item index="equity" route="/equity">
						<div class="head-mune-title">企业权益</div>
					</el-menu-item>
					<el-menu-item index="standard" route="/standard">
						<div class="head-mune-title">标准化管理平台</div>
					</el-menu-item>
					<el-menu-item index="qa" route="/QA?helpClassID=134">
						<div class="head-mune-title">常见问题</div>
					</el-menu-item>
					<el-menu-item index="newCenter" route="/newCenter">
						<div class="head-mune-title">新闻中心</div>
					</el-menu-item>
					<el-menu-item index="contactUs" route="/contactUs">
						<!--  route="/contactUs" -->
						<div class="head-mune-title">联系我们</div>
						<!-- <a href="https://www.otopchina.com/contact.html" class="head-mune-title">联系我们</a> -->
					</el-menu-item>

					<!-- <el-menu-item>
						<a href="https://shop92427676.m.youzan.com/v2/showcase/homepage?alias=wJ0svjq8Na"
							target="_blank" class="head-mune-title">电商平台</a>
					</el-menu-item> -->
				</el-menu>
			</div>
			<a href="javascript:;" v-if="!hasUser" class="head-login" @click="showLoginBox">登录/注册</a>



			<el-popover placement="top" width="60" v-model="visible" trigger="hover" v-else>
				<router-link v-show="localStorageUserInfo.companyType == -1" to="/type"
					style="color:#606266;letter-spacing:1px;margin-bottom:5px;">用户认证
				</router-link>
				<router-link v-show="localStorageUserInfo.companyType >0" to="/userCenter"
					style="color:#606266;letter-spacing:1px;margin-bottom:5px;">个人中心
				</router-link>
				<div style="width: 100%;height: 1px;background-color: #DCDFE6;margin: 10px 0px;display: block;"></div>
				<a href="javascript:;" @click="outLogin"
					style="color:#606266;letter-spacing:1px;marign-top:5px;">退出登录</a>
				<router-link to="/userCenter" slot="reference" v-if="localStorageUserInfo.companyType >0">
					<div class="userLogo2">
						<div class="userLogoImg">
							<img :src="localStorageUserInfo.head_pic?localStorageUserInfo.head_pic:'http://cdmfimg.changdemifen.com/20220715_83a728f133b3374e547176016d505632'"
								class="img_fluid">
						</div>
						{{localStorageUserInfo.nickname}}
					</div>
				</router-link>
				<div to="/userCenter" slot="reference" v-else>
					<div class="userLogo2">
						<div class="userLogoImg">
							<img :src="localStorageUserInfo.head_pic?localStorageUserInfo.head_pic:'http://cdmfimg.changdemifen.com/20220715_83a728f133b3374e547176016d505632'"
								class="img_fluid">
						</div>
						{{localStorageUserInfo.nickname}}
					</div>
				</div>

			</el-popover>

		</div>


		<div class="m-head show_m">
			<router-link to="/" class="head-logo-box">
				<!-- <img src="../../static/images/bclogo.png" class="bancanLogo"> -->
				<img src="../../static/images/logo.png" class="m-headLogo" style="height:45px;">
			</router-link>


			<el-button slot="reference" class="headM_menu_btn" @click="changeShowMenu()"
				style="background: #fff !important;">
				<img src="../../static/images/menu.svg" alt="" class="headM_menu_logo" />
			</el-button>


			<transition name="el-fade-in-linear">
				<ul class="headM_menu_list" v-if="showMenu">
					<li class="headM_menu_item"><router-link to="/">首页</router-link></li>
					<li class="headM_menu_item"><router-link to="/aboutserve">服务体系</router-link></li>
					<li class="headM_menu_item"><router-link to="/aboutotop">平台介绍</router-link></li>
					<li class="headM_menu_item"><router-link to="/equity">企业权益</router-link></li>
					<li class="headM_menu_item"><router-link to="/QA?helpClassID=134">常见问题</router-link></li>
					<li class="headM_menu_item"><router-link to="/newCenter">新闻中心</router-link></li>
					<li class="headM_menu_item"><router-link to="/contactUs">联系我们</router-link></li>

					<li class="headM_menu_item">
						<div class="head_btns">
							
							<a href="javascript:;" v-if="!hasUser" class="head-login-m" @click="showLoginBox">登录/注册</a>

							<el-popover placement="top" width="60" v-model="visible" trigger="hover" v-else>
								<router-link to="/userCenter"
									style="color:#606266;letter-spacing:1px;margin-bottom:5px;">个人中心
								</router-link>
								<div
									style="width: 100%;height: 1px;background-color: #DCDFE6;margin: 10px 0px;display: block;">
								</div>
								<a href="javascript:;" @click="outLogin"
									style="color:#606266;letter-spacing:1px;marign-top:5px;">退出登录</a>
								<router-link to="/userCenter" slot="reference">
									<div class="userLogo2">
										<div class="userLogoImg">
											<img :src="userInfo.head_pic?userInfo.head_pic:'http://cdmfimg.changdemifen.com/20220715_83a728f133b3374e547176016d505632'"
												class="img_fluid">
										</div>
										{{userInfo.nickname}}
									</div>
								</router-link>
							</el-popover>
						</div>
					</li>

				</ul>
			</transition>


		</div>



		<div class="head-menu">
			<!-- <div class="line"></div> -->
			<div class="headLogin" v-show="showLogin">
				<div class="headLoginBox">
					<div class="closeBox" @click="closeLoginBox">
						<img src="https://cdmfimg.changdemifen.com/20230505_56cb8997ba28e01f2a6a97bd007dd3f8">
					</div>
					<el-tabs v-model="activeName" @tab-click="handleClick" class="headLoginBoxTab">
						<el-tab-pane label="登录" name="login">
							<el-form :model="loginInfo" class="headLoginForm" @keydown.enter="login">
								<el-form-item label="手机号" class="headLoginFormItem">
									<el-input v-model="loginInfo.username" placeholder="填写您的手机号"></el-input>
								</el-form-item>
								<el-form-item label="密码" prop="pass" class="headLoginFormItem">
									<el-input type="password" v-model="loginInfo.password" autocomplete="off"
										placeholder="请输入密码">
									</el-input>
								</el-form-item>
								<div class="checkXieYi">
									<el-checkbox v-model="loginInfo.checked">我同意并已知晓
									</el-checkbox>
									<router-link :to="{name:'helpList', params: {helpClassID:125}}" class="loginXieyi">
										《平台协议》
									</router-link>
									<router-link :to="{name:'helpList', params: {helpClassID:126}}" class="loginXieyi">
										《用户隐私协议》
									</router-link>
								</div>

								<el-button class="loginBtn" @click="login">
									立即登录
								</el-button>
								<div class="loginBoxOption">
									<a href="javascript:;" @click="showFandLogin=true">忘记密码？</a>
									<a href="javascript:;" @click="goReg">去注册</a>
								</div>
							</el-form>
						</el-tab-pane>
						<el-tab-pane label="注册账号" name="reg">
							<el-form :model="register" class="headLoginForm" :rules="regRules">
								<el-form-item label="手机号" class="headLoginFormItem" prop="mobile">
									<el-input v-model="register.mobile" placeholder="填写您的手机号"></el-input>
								</el-form-item>
								<el-form-item label="验证码" class="headLoginFormItem yzm" prop="code">
									<el-input v-model="register.code" placeholder="填写您的验证码">
										<el-button slot="append" @click="sendMobileCode(register.mobile)"
											:disabled="codeCD!=0">
											{{codeCD==0?'获取验证码':codeCD+'后重新获取'}}
										</el-button>
									</el-input>
								</el-form-item>
								<el-form-item label="密码" prop="password" class="headLoginFormItem">
									<el-input type="password" v-model="register.password" autocomplete="off"
										placeholder="请输入密码">
									</el-input>
								</el-form-item>
								<el-form-item label="确认密码" prop="passwordC" class="headLoginFormItem">
									<el-input type="password" v-model="register.passwordC" autocomplete="off"
										placeholder="请再次输入密码">
									</el-input>
								</el-form-item>
								<div class="checkXieYi">
									<el-checkbox v-model="register.checked">我同意并已知晓
									</el-checkbox>
									<router-link :to="{name:'helpList', params: {helpClassID:125}}" class="loginXieyi">
										《平台协议》
									</router-link>
									<router-link :to="{name:'helpList', params: {helpClassID:126}}" class="loginXieyi">
										《用户隐私协议》
									</router-link>
								</div>

								<el-button class="loginBtn" @click="theregister">
									立即注册
								</el-button>
							</el-form>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>

			<div class="headLogin fandPass" v-show="showFandLogin">
				<div class="headLoginBox">
					<div class="closeBox" @click="showFandLogin=false">
						<img src="https://cdmfimg.changdemifen.com/20230505_56cb8997ba28e01f2a6a97bd007dd3f8">
					</div>
					<el-tabs v-model="activeName2" class="headLoginBoxTab headLoginBoxTab2">
						<el-tab-pane label="找回密码" name="reg">
							<el-form :model="fandPass" class="headLoginForm" :rules="fandPassRules">
								<el-form-item label="手机号" class="headLoginFormItem" prop="mobile">
									<el-input v-model="fandPass.mobile" placeholder="填写您的手机号"></el-input>
								</el-form-item>
								<el-form-item label="验证码" class="headLoginFormItem yzm" prop="code">
									<el-input v-model="fandPass.code" placeholder="填写您的验证码">
										<el-button slot="append" @click="sendMobileCode(fandPass.mobile)"
											:disabled="codeCD!=0">
											{{codeCD==0?'获取验证码':codeCD+'后重新获取'}}
										</el-button>
									</el-input>
								</el-form-item>
								<el-form-item label="密码" prop="password" class="headLoginFormItem">
									<el-input type="password" v-model="fandPass.password" autocomplete="off"
										placeholder="请输入新的密码">
									</el-input>
								</el-form-item>
								<el-form-item label="确认密码" prop="passwordC" class="headLoginFormItem">
									<el-input type="password" v-model="fandPass.passwordC" autocomplete="off"
										placeholder="请再次输入新的密码">
									</el-input>
								</el-form-item>

								<el-button class="loginBtn" @click="forgetPass">
									确定更改密码
								</el-button>
							</el-form>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>


		</div>
	</header>
</template>

<script>
	import axios from "@/utils/request"
	export default {
		name: 'baseHead',
		props: {
			headActiveIndex: String
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.register.password) {
					callback(new Error('请确认两次密码一致'));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.fandPass.password) {
					callback(new Error('请确认两次密码一致'));
				} else {
					callback();
				}
			};
			return {
				// headActiveIndex: '1',
				activeName: 'login',
				activeName2: 'reg',
				loginInfo: {
					username: '',
					password: '',
					checked: false,
				},
				register: {
					mobile: '',
					code: '',
					password: '',
					passwordC: '',
				},
				fandPass: {
					mobile: '',
					code: '',
					password: '',
					passwordC: '',
				},
				showLogin: false,
				showFandLogin: false,
				localStorageUserInfo: {},
				hasUser: false,
				codeCD: 0,
				visible: false,
				regRules: {
					mobile: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							min: 11,
							max: 11,
							message: '请输入正确的手机号',
							trigger: 'blur'
						},
					],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}],
					password: [{
							required: true,
							message: '请输入正确的密码',
							trigger: 'change'
						},
						// {
						// 	min: 6,
						// 	max: 16,
						// 	message: '密码长度在6到16个字符',
						// 	trigger: 'blur'
						// }
					],
					passwordC: [{
							required: true,
							message: '请再次输入密码',
							trigger: 'change'
						},
						// {
						// 	min: 6,
						// 	max: 16,
						// 	message: '密码长度在6到16个字符',
						// 	trigger: 'blur'
						// },
						{
							validator: validatePass,
							trigger: 'blur',
							required: true
						}
					],
				},
				fandPassRules: {
					mobile: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '请输入正确的手机号',
							trigger: 'blur'
						},
					],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}],
					password: [{
							required: true,
							message: '请输入正确的密码',
							trigger: 'change'
						},
						// {
						// 	min: 6,
						// 	max: 16,
						// 	message: '密码长度在6到16个字符',
						// 	trigger: 'blur'
						// }
					],
					passwordC: [{
							required: true,
							message: '请再次输入密码',
							trigger: 'change'
						},
						// {
						// 	min: 6,
						// 	max: 16,
						// 	message: '密码长度在6到16个字符',
						// 	trigger: 'blur'
						// },
						{
							validator: validatePass2,
							trigger: 'blur',
							required: true
						}
					],
				},
				message: '我是来自子组件的消息',
				checkLoginStatus: false,
				showMenu: false,
			};
		},
		methods: {
			handleSelect(key, keyPath) {},
			handleClick(tab, event) {},
			login() {
				var vm = this
				var data = {
					appData: {
						clientType: 1,
						mobile: vm.loginInfo.username,
						password: vm.loginInfo.password,
					},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
					// token: vm.userInfo.token
				}
				if (vm.loginInfo.checked) {
					if (vm.loginInfo.username.length > 0 && vm.loginInfo.password.length > 0) {
						axios.post("ProgramLogin/loginPass", data).then(res => {
							if (res.data.code == 1) {
								vm.$message({
									type: 'success',
									message: res.data.msg,
									duration: 3000,
									showClose: true
								});
								localStorage.setItem('token', res.data.data.token);
								var data2 = {
									appData: {
										clientType: 1,
									},
									version: 2.1,
									sign: "3b0b8438b5c685e7e396f9e7c0394a41",
									time: 1642472216346,
									token: res.data.data.token
								}
								axios.post("User/getUserInfo", data2).then(res => {
									if (res.data.code == 1) {
										localStorage.setItem('user_token', JSON.stringify(res.data.data));
										vm.localStorageUserInfo = res.data.data
										// this.$localData('set', 'tokenData', {
										//   token: JSON.stringify(res.data.data), //登录成功后返回的token
										//   tokenTime: Date.parse(new Date()) / 1000  //获取token的时间
										// })

										vm.checkLoginStatus = true
										vm.hasUser = true
										vm.$router.go(0)
										vm.closeLoginBox()
										vm.$emit('childFn', this.checkLoginStatus); //通过childFn 将值传递给父页面
									} else {
										vm.$message({
											type: 'error',
											message: res.data.msg,
											duration: 3000,
											showClose: true
										});
									}
								})

							} else {
								vm.$message({
									type: 'error',
									message: res.data.msg,
									duration: 3000,
									showClose: true
								});
							}
						})
					} else {
						vm.$message({
							type: 'error',
							message: "请填写登录手机号及密码",
							duration: 3000,
							showClose: true
						});
					}
				} else {
					vm.$message({
						type: 'error',
						message: "请勾选“我同意并知晓《平台协议》”",
						duration: 3000,
						showClose: true
					});
				}

			},
			closeLoginBox() {
				var vm = this
				vm.showLogin = false
			},
			showLoginBox() {
				var vm = this
				vm.showLogin = true
			},
			sendMobileCode(row) {
				var vm = this
				var data = {
					appData: {
						mobile: vm.fandPass.mobile || vm.register.mobile,
						clientType: 1,
					},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
				}

				axios.post("ProgramLogin/SendMobileCode", data).then(res => {
					if (res.data.code == 1) {
						vm.code = res.data.data

						vm.codeCD = 60
						var i = setInterval(() => {
							if (vm.codeCD > 0) {
								// vm.smsbtn = true
								vm.daojishi()
							} else {
								// vm.smsbtn = false
								clearInterval(i)
							}
						}, 1000)
					} else {
						vm.$message({
							type: 'error',
							message: res.data.msg,
							duration: 3000,
							showClose: true
						});
					}
				})
			},
			theregister() {
				var vm = this

				if (!vm.register.mobile || !vm.register.code || !vm.register.password || !vm.register.passwordC) {
					vm.$message({
						type: 'error',
						message: "请补齐注册信息",
						duration: 3000,
						showClose: true
					});
				} else if (vm.register.password != vm.register.passwordC) {
					vm.$message({
						type: 'error',
						message: "请确认两次密码一致",
						duration: 3000,
						showClose: true
					});
				} else if (vm.register.mobile.length != 11) {
					vm.$message({
						type: 'error',
						message: "请输入正确的手机号",
						duration: 3000,
						showClose: true
					});
				} else if (!vm.register.checked) {
					vm.$message({
						type: 'error',
						message: "请勾选“我同意并知晓《平台协议》”",
						duration: 3000,
						showClose: true
					});
				}
				// else if (vm.register.password.length > 6 && vm.register.password.length < 16) {
				// 	vm.$message({
				// 		type: 'error',
				// 		message: "密码长度在6到16个字符",
				// 		duration: 3000,
				// 		showClose: true
				// 	});
				// } 
				else {
					var data = {
						appData: {
							mobile: vm.register.mobile,
							code: vm.register.code,
							password: vm.register.password,
							passwordC: vm.register.passwordC,
							clientType: 1,
						},
						version: 2.1,
						sign: "3b0b8438b5c685e7e396f9e7c0394a41",
						time: 1642472216346,
						// token: localStorage.getItem('token'),
					}
					axios.post("ProgramLogin/reg", data).then(res => {
						if (res.data.code == 1) {

							vm.loginInfo.username = vm.register.mobile,
								vm.loginInfo.password = vm.register.password,
								vm.login(), vm.$message({
									type: 'success',
									message: "注册成功，已为您自动登录该账号",
									duration: 3000,
									showClose: true
								});
						} else {
							vm.$message({
								type: 'error',
								message: res.data.msg,
								duration: 3000,
								showClose: true
							});
						}
					})
				}

			},
			daojishi() {
				var vm = this
				vm.codeCD = vm.codeCD - 1
			},
			goReg() {
				this.activeName = 'reg'
			},
			outLogin() {
				localStorage.clear("token"), localStorage.clear("user_token"), this.hasUser = false, this.$router.go(0)
			},
			forgetPass() {
				var vm = this

				if (!vm.fandPass.mobile || !vm.fandPass.code || !vm.fandPass.password || !vm.fandPass.passwordC) {
					vm.$message({
						type: 'error',
						message: "请补齐注册信息",
						duration: 3000,
						showClose: true
					});
				} else if (vm.fandPass.password != vm.fandPass.passwordC) {
					vm.$message({
						type: 'error',
						message: "请确认两次密码一致",
						duration: 3000,
						showClose: true
					});
				} else if (vm.fandPass.mobile.length != 11) {
					vm.$message({
						type: 'error',
						message: "请输入正确的手机号",
						duration: 3000,
						showClose: true
					});
				}
				// else if (vm.fandPass.password.length > 6 && vm.fandPass.password.length < 16) {
				// 	vm.$message({
				// 		type: 'error',
				// 		message: "密码长度在6到16个字符",
				// 		duration: 3000,
				// 		showClose: true
				// 	});
				// }
				else {
					var data = {
						appData: {
							mobile: vm.fandPass.mobile,
							code: vm.fandPass.code,
							newPass: vm.fandPass.password,
							newPassConfirm: vm.fandPass.passwordC,
							clientType: 1,
						},
						version: 2.1,
						sign: "3b0b8438b5c685e7e396f9e7c0394a41",
						time: 1642472216346,
						// token: localStorage.getItem('token'),
					}
					axios.post("Index/forgetPass", data).then(res => {
						if (res.data.code == 1) {
							vm.$message({
								type: 'success',
								message: res.data.msg,
								duration: 3000,
								showClose: true
							});
						} else {
							vm.$message({
								type: 'error',
								message: res.data.msg,
								duration: 3000,
								showClose: true
							});
						}
					})
				}
			},

			changeShowMenu() {
				var that = this
				if (that.showMenu === false) {
					that.showMenu = true
				} else {
					that.showMenu = false
				}
			},
		},
		created() {
			if (localStorage.getItem('token')) {
				// this.localStorageUserInfo = JSON.parse(localStorage.getItem('user_token'))
				var token = localStorage.getItem('token')
				var data2 = {
					appData: {
						clientType: 1,
					},
					version: 2.1,
					sign: "3b0b8438b5c685e7e396f9e7c0394a41",
					time: 1642472216346,
					token: token
				}
				axios.post("User/getUserInfo", data2).then(res => {
					if (res.data.code == 1) {
						localStorage.setItem('user_token', JSON.stringify(res.data.data));
						this.localStorageUserInfo = res.data.data
						this.hasUser = true
					} else {
						this.$message({
								type: 'error',
								message: "登录以超时，请重新登录",
								duration: 3000,
								showClose: true
							}), localStorage.clear("token"), localStorage.clear("user_token"),
							this.hasUser = false
					}
				})
			}
		},
		mounted() {
			// var vm = this
		}
	}
</script>

<style>
	.head {
		background: #fff !important;
		padding: 24px 9.5%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.bancanLogo {
		width: 14%;
		height: auto;
	}

	.headLogo1 {
		width: 400px;
		height: auto;
		margin-left: 15px;
	}

	.head-login {
		background: linear-gradient(98.55deg, #FF6B5A -6.05%, #FF5234 131.99%);
		border-radius: 8px;
		padding: 15px 40px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		color: #FFFFFF;
		font-size: 16px;
		line-height: 22px;
		white-space: nowrap;
		margin-left: 20px;
	}
	
	.head-menu {
		width: 100%;
	}

	.el-menu-base>.el-menu-item {
		height: unset !important;
		line-height: unset !important;
		padding: 10px 0 !important;
	}

	.el-menu-base>.el-menu-item:hover {
		background: #fff !important;
	}

	.el-menu-base>.el-menu-item:hover .head-mune-title {
		color: #FF4926 !important;
		color: 14px;
	}

	.el-menu-base>.el-submenu .el-submenu__title {
		height: unset !important;
		line-height: unset !important;
		padding: 10px 0 !important;
	}

	.el-menu-base>.el-submenu .el-submenu__title:hover {
		background: #fff !important;
	}

	.el-menu-base>.el-submenu .el-submenu__title:hover .head-mune-title {
		color: #FF4926 !important;
	}

	.el-menu-base>.el-menu-item.is-active {
		background: #fff !important;
		color: #FF4926 !important;
		border-radius: 0px !important;
		border-bottom: unset !important;
	}

	.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
		border-bottom: unset !important;
	}

	.el-menu.el-menu--horizontal {
		border-bottom: 0px !important;
	}

	.el-menu--horizontal .el-menu .el-menu-item:not(.is-disabled):hover {
		color: #FF4926 !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		display: none;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		border: unset !important;
	}

	.head-menu .el-menu {
		background: #fff;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	@media screen and (min-width:769px) and (max-width:1300px) {
		.head {
			background: #fff !important;
			padding: 20px 30px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.head-menu .el-menu .el-menu-item {
			text-align: center;
			color: #000;
			font-size: 15px;
			margin: 0px 10px;
			font-weight: 600;
		}
	}

	@media screen and (min-width:1301px) {
		.head-menu .el-menu .el-menu-item {
			text-align: center;
			color: #000;
			font-size: 15px;
			margin: 0px 15px;
			font-weight: 600;
		}

		.head-menu .el-menu .el-submenu {
			text-align: center;
			color: #000;
			font-size: 15px;
			margin: 0px 15px;
			font-weight: 600;
			/* border-left: 0.6px solid #FFFFFF; */
		}
	}

	@media screen and (max-width:1300px) {
		.head-menu .el-menu .el-menu-item {
			text-align: center;
			color: #000;
			font-size: 15px;
			margin: 0px 10px;
			font-weight: 600;
		}

		.head-menu .el-menu .el-submenu {
			text-align: center;
			color: #000;
			font-size: 16px;
			margin: 0px 15px;
			font-weight: 600;
			/* border-left: 0.6px solid #FFFFFF; */
		}
	}

	.head-logo-box {
		display: flex;
	}

	.head-menu .el-menu-base .el-submenu .el-submenu__title {
		color: #000;
		font-size: 16px;
		font-weight: 600;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		color: #FFFFFF;
		font-size: 18px;
		padding: 0px;
	}

	/* 	.head-menu .el-menu-item:hover {
		background: #FDEED0 !important;
		color: #171717 !important;
	}

	.head-menu .el-menu .el-submenu .head-mune-title:hover {
		background: #FDEED0 !important;
		color: #171717 !important;
	}

	.head-menu>.el-submenu__title:hover {
		background: #FDEED0 !important;
		color: #171717 !important;
	}

	.head-menu>.is-opened .el-submenu__title {
		background: #FDEED0 !important;
		color: #171717 !important;
	} */

	.head-menu>.is-opened .el-submenu__title .head-mune-title {
		color: #171717 !important;
	}

	.head-mune-title {
		/* border-right: 0.6px solid rgba(255, 255, 255, 0.7); */
		/* padding: 5px 0; */
	}

	.el-menu .el-submenu:last-child .head-mune-title {
		border-right: 0px;
	}

	.headLogin {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		/* transform: translate(-50%, -50%); */
		z-index: 999;
		/* overflow: hidden; */
		background: rgba(0, 0, 0, 0.6);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.fandPass {
		z-index: 1000 !important;
	}

	.headLoginBox {
		background: #fff;
		border-radius: 8px;
		position: relative;
	}

	.headLoginBoxTab {}

	.headLoginBoxTab>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.el-tabs__item {
		text-align: center;
		width: 200px;
		background: #F1F1F1;
		color: #969696;
		/* font-weight: bold; */
		font-size: 16px;
		font-weight: 600;
	}

	.headLoginBoxTab2>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.is-active {}

	.headLoginBoxTab2 .el-tabs__header .el-tabs__nav-wrap {
		background: #F1F1F1 !important;
	}

	.headLoginBoxTab2>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav .el-tabs__item:nth-child(2) {
		border-radius: 0px 0px 0px 0px !important;
		/* box-shadow: inset -5px -3px 8px -12px rgba(0, 0, 0, 0.19); */
	}

	.headLoginBoxTab>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav .el-tabs__item:nth-child(2) {
		border-radius: 8px 0px 8px 0px;
		/* box-shadow: inset -5px -3px 8px -12px rgba(0, 0, 0, 0.19); */
	}

	.headLoginBoxTab>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav .el-tabs__item:nth-child(3) {
		border-radius: 0px 8px 0px 8px;
		/* box-shadow: inset 6px 3px 8px -8px rgba(0, 0, 0, 0.19); */
	}

	.headLoginBoxTab>.el-tabs__header>.el-tabs__nav-wrap>.el-tabs__nav-scroll>.el-tabs__nav>.is-active {
		background: #fff;
		color: #171717;
	}

	.headLoginBoxTab .el-tabs__content .el-tab-pane {
		padding: 10px 30px 30px 30px;

	}

	.headLoginForm {}

	.headLoginFormItem .el-form-item__label {
		font-size: 16px;
		font-weight: bold;
	}

	.headLoginFormItem .el-form-item__content .el-input .el-input__inner {
		border: none !important;
		border-bottom: 1px solid rgba(0, 0, 0, 0.101961) !important;
	}

	.loginXieyi {
		color: #FF4926;
		font-size: 14px;
	}

	.checkXieYi {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #FF4926 !important;
		border-color: #FF4926 !important;
	}

	.el-checkbox__inner:hover {
		border-color: #FF4926 !important;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #000 !important;
	}

	.loginBtn {
		width: 100%;
		margin-top: 15px !important;
		background: linear-gradient(112.11deg, #FF7164 0%, #FF4926 106.67%) !important;
		border-radius: 8px;
		color: #fff !important;

	}

	.head-menu>.el-menu>.is-active {
		border-bottom: 0px !important;
		background: #fff !important;
		color: #FF4926 !important;
	}

	.loginBoxOption {
		width: 100%;
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.loginBoxOption a {
		color: #9E9E9E;
		padding: 5px;
	}

	.closeBox {
		position: absolute;
		top: 0px;
		right: -45px;
		z-index: 999;
		width: 28px;
		height: 28px;
		cursor: pointer;
	}

	.closeBox img {
		width: 100%;
		height: auto;
	}

	.userLogo2 {
		/* width: 200px; */
		color: #000;
		font-size: 16px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		font-weight: 600;
		white-space: nowrap;
		margin-left: 20px;
	}

	.userLogoImg {
		width: 40px;
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		margin-right: 10px;
		border-radius: 50px;
	}

	.el-menu-base .el-menu-item {
		padding: unset;
	}

	.yzm .el-form-item__content .el-input .el-input-group__append {
		background-color: #fff !important;
		border-bottom: 1px solid rgba(0, 0, 0, 0.101961) !important;
		overflow: hidden;
	}

	.yzm .el-form-item__content .el-input .el-input-group__append .el-button {
		background-color: #fff !important;
		border-radius: 0px !important;
		color: #576B95;
	}




	@media screen and (min-width: 769px) {
		.show_m {
			display: none !important;
		}
	}

	@media screen and (max-width: 768px) {
		.show_pc {
			display: none !important;
		}

		.headM_logo {
			width: 130px;
			height: auto;
		}

		.m-head {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 15px 10px;
			box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}

		.headM_menu_logo {
			width: 24px;
			height: auto;
		}

		.headM_menu_btn {
			padding: 5px !important;
		}

		.headM_menu_list {
			position: absolute;
			top: 76px;
			left: 0px;
			right: 0px;
			background: #fff;
			z-index: 1999;
			/* transition: all 1s; */
			padding: 15px;
			box-shadow: -3px 5px 5px 1px rgba(0, 0, 0, 0.1);
		}

		.headM_menu_item {
			list-style: none;
			padding: 15px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}

		.headM_menu_item a {
			text-decoration: none;
			color: #000;
			/* width: 100px; */
			/* text-align-last: justify; */
		}

		.head_btns {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			/* margin-left: 60px; */
			margin-left: unset;
		}
		
		
		.head-login-m{
			background: linear-gradient(98.55deg, #FF6B5A -6.05%, #FF5234 131.99%);
			border-radius: 8px;
			padding: 5px 20px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: #FFFFFF;
			font-size: 16px;
			line-height: 22px;
			white-space: nowrap;
			color:#fff !important;
		}
	}
</style>