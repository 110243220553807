import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAnimateNumber from 'vue-animate-number'


import MetaInfo from 'vue-meta-info'
// import 'ant-design-vue/dist/antd.css';



// import html2canvas from 'html2canvas'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/' //关键代码

Vue.use(ElementUI);
Vue.use(VueAnimateNumber)
Vue.use(MetaInfo)


import VueUeditorWrap from 'vue-ueditor-wrap';
Vue.component('vue-ueditor-wrap', VueUeditorWrap);


Vue.directive('title', { //单个修改标题
	inserted: function(el, binding) {
		document.title = el.dataset.title
	}
})

var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
(function() {
	var hm = document.createElement("script");
	hm.src = "https://hm.baidu.com/hm.js?274fa8d88770f574f120eec64003fc09";
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(hm, s);
})();


new Vue({
	el: '#app',
	router,
	render: h => h(App),
	mounted() {
	}
}).$mount('#app')
