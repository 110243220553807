// 得先安装axios  npm i --save axios
import axios from "axios";
import {
	Message,
} from 'element-ui' // 引入消息提示

axios.defaults.headers.post['Content-Type'] = 'application/json'; //设置post的默认请求类型，有的可能是fromdata格式，需要单独设置

//创建请求，是指请求超时时间以及请求地址
const request = axios.create({
	//
	baseURL: 'https://cardapi.otopchina.com/groupprogram/v1/',
	timeout: 5000 // 请求超时时间
})


// 添加请求拦截器
request.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	//config.headers.Authorization = window.sessionStorage.getItem('token')
	//最后必须return config 这是固定写法
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

//响应拦截器即异常处理
request.interceptors.response.use(
	(response) => {
		if (response.status == "301") {
			sessionStorage.clear();
			Message.error({
				message: '由于您长时间未操作，登录状态已失效',
			})
			router.replace({
				path: "/HomeView", // 重新回到登陆页
			});
		}else if(response.status == "404"){
			console.log("请求路径不存在")
		}else if(response.status == "500"){
			console.log(response.status)
			console.log("服务器错误")
		}
		return response;
	},
	(err) => {
		return Promise.resolve(err.response);
	}
);


// 导出
export default request
