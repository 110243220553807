import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'



Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	// {
	// 	path: '/login',
	// 	name: 'login',
	// 	component: () => import('../views/login.vue')
	// },
	{
		path: '/serviceProviders',
		name: 'serviceProviders',
		component: () => import('../views/serviceProviders.vue')
	},
	{
		path: '/serviceprovider',
		name: 'serviceprovider',
		component: () => import('../views/serviceProvider.vue')
	},
	{
		path: '/type',
		name: 'type',
		component: () => import('../views/serviceproviders/type.vue')
	},
	{
		path: '/result',
		name: 'result',
		component: () => import('../views/serviceproviders/result.vue')
	},
	{
		path: '/authentication',
		name: 'authentication',
		component: () => import('../views/serviceproviders/authentication.vue')
	},
	{
		path: '/userCenter',
		name: 'userCenter',
		component: () => import('../views/userCenter.vue')
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('../views/search.vue')
	},
	{
		path: '/myCompany',
		name: 'myCompany',
		component: () => import('../views/myCompany.vue')
	},
	{
		path: '/myProduct',
		name: 'myProduct',
		component: () => import('../views/myProduct.vue')
	},
	{
		path: '/contactUs',
		name: 'contactUs',
		component: () => import('../views/contactUs.vue')
	},
	{
		path: '/mesCenter',
		name: 'mesCenter',
		component: () => import('../views/mesCenter.vue')
	},
	{
		path: '/myInfo',
		name: 'myInfo',
		component: () => import('../views/myInfo.vue')
	},
	{
		path: '/coServices',
		name: 'coServices',
		component: () => import('../views/coServices.vue')
	},
	{
		path: '/companyDetail',
		name: 'companyDetail',
		component: () => import('../views/companyDetail.vue')
	},
	{
		path: '/productDetail',
		name: 'productDetail',
		component: () => import('../views/productDetail.vue'),
		meta: {
			title: '中国一乡一品公共服务平台'
		}
	},
	{
		path: '/myInvoice',
		name: 'myInvoice',
		component: () => import('../views/myInvoice.vue')
	},
	{
		path: '/payCenter',
		name: 'payCenter',
		component: () => import('../views/payCenter.vue')
	},
	{
		path: '/packagingReview',
		name: 'packagingReview',
		component: () => import('../views/packagingReview.vue')
	},
	{
		path: '/plaque',
		name: 'plaque',
		component: () => import('../views/plaque.vue')
	},
	{
		path: '/buyACID',
		name: 'buyACID',
		component: () => import('../views/buyACID.vue')
	},
	{
		path: '/newCenter',
		name: 'newCenter',
		component: () => import('../views/newCenter.vue')
	},
	{
		path: '/newDetail',
		name: 'newDetail',
		component: () => import('../views/newDetail.vue')
	},
	{
		path: '/productList',
		name: 'productList',
		component: () => import('../views/productList.vue')
	},
	{
		path: '/companyList',
		name: 'companyList',
		component: () => import('../views/companyList.vue')
	},
	{
		path: '/mesDetail',
		name: 'mesDetail',
		component: () => import('../views/mesDetail.vue')
	},
	{
		path: '/aboutserve',
		name: 'aboutserve',
		component: () => import('../views/liuJiaYi/aboutserve.vue')
	},
	{
		path: '/aboutotop',
		name: 'aboutotop',
		component: () => import('../views/liuJiaYi/aboutotop.vue')
	},
	// 服务体系优势
	{
		path: '/advantage',
		name: 'advantage',
		component: () => import('../views/liuJiaYi/advantage.vue')
	},
	// 服务体系综合解决方案
	{
		path: '/programme',
		name: 'programme',
		component: () => import('../views/liuJiaYi/programme.vue')
	},
	// 服务体系相关案例
	{
		path: '/case',
		name: 'case',
		component: () => import('../views/liuJiaYi/case.vue')
	},
	
	// 服务体系相关案例
	{
		path: '/aboutserviceProvider',
		name: 'aboutserviceProvider',
		component: () => import('../views/liuJiaYi/aboutserviceProvider.vue')
	},
	
	{
		path: '/union',
		name: 'union',
		component: () => import('../views/liuJiaYi/union.vue')
	},
	{
		path: '/idcard',
		name: 'idcard',
		component: () => import('../views/liuJiaYi/idcard.vue')
	},
	{
		path: '/applicationCriteria',
		name: 'applicationCriteria',
		component: () => import('../views/liuJiaYi/applicationCriteria.vue')
	},
	{
		path: '/applicationScope',
		name: 'applicationScope',
		component: () => import('../views/liuJiaYi/applicationScope.vue')
	},
	{
		path: '/applyJoin',
		name: 'applyJoin',
		component: () => import('../views/liuJiaYi/applyJoin.vue')
	},
	{
		path: '/equity',
		name: 'equity',
		component: () => import('../views/liuJiaYi/equity.vue')
	},
	{
		path: '/QA',
		name: 'QA',
		component: () => import('../views/qa/QA.vue')
	},
	{
		path: '/qa2',
		name: 'qa2',
		component: () => import('../views/qa/qa2.vue')
	},
	{
		path: '/qa3',
		name: 'qa3',
		component: () => import('../views/qa/qa3.vue')
	},
	{
		path: '/qa4',
		name: 'qa4',
		component: () => import('../views/qa/qa4.vue')
	},
	{
		path: '/qa5',
		name: 'qa5',
		component: () => import('../views/qa/qa5.vue')
	},
	{
		path: '/qa6',
		name: 'qa6',
		component: () => import('../views/qa/qa6.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('../views/help.vue')
	},
	{
		path: '/helpSearch',
		name: 'helpSearch',
		component: () => import('../views/helpSearch.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product.vue'),
		meta: {
			title: '中国一乡一品公共服务平台'
		}
	},
	{
		path: '/FAQ',
		name: 'FAQ',
		component: () => import('../views/FAQ.vue')
	},
	{
		path: '/helpList',
		name: 'helpList',
		component: () => import('../views/helpList.vue')
	},
	{
		path: '/edi',
		name: 'edi',
		component: () => import('../views/edi.vue')
	},
	// {
	// 	path: '/test',
	// 	name: 'test',
	// 	component: () => import('../views/test.vue')
	// },
	// {
	// 	path: '/standard',
	// 	name: 'standard',
	// 	component: () => import('../views/standard/standard.vue')
	// },
	// {
	// 	path: '/standardAbout',
	// 	name: 'standardAbout',
	// 	component: () => import('../views/standard/standardAbout.vue')
	// },
	// {
	// 	path: '/standardFile',
	// 	name: 'standardFile',
	// 	component: () => import('../views/standard/standardFile.vue')
	// },
	// {
	// 	path: '/download',
	// 	name: 'standardDownload',
	// 	component: () => import('../views/standard/download.vue')
	// },
	// {
	// 	path: '/standardContact',
	// 	name: 'standardContact',
	// 	component: () => import('../views/standard/contact.vue')
	// },
	// {
	// 	path: '/standardComplaint',
	// 	name: 'standardcomplaint',
	// 	component: () => import('../views/standard/complaint.vue')
	// },
	// {
	// 	path: '/standardApplication',
	// 	name: 'standardApplication',
	// 	component: () => import('../views/standard/application.vue')
	// },
	// {
	// 	path: '/myProject',
	// 	name: 'myProject',
	// 	component: () => import('../views/standard/myProject.vue')
	// },
	
	// {
	// 	path: '/industrialAlliance',
	// 	name: 'industrialAlliance',
	// 	component: () => import('../views/industrialAlliance/industrialAlliance.vue')
	// },

]
// const scrollBehavior = (to, from, savedPosition) => {
// 	if (savedPosition && to.meta.keepAlive) {
// 		return savedPosition;
// 	}
// 	return {
// 		left: 0,
// 		top: 0
// 	};
// }

// const scrollBehavior2 = (to, from, saveTop) => {
// 	if (saveTop) {
// 		return saveTop;
// 	} else {
// 		return {
// 			x: 0,
// 			y: 0
// 		}

// 	}
// }

const router = new VueRouter({
	// base:process.env.publicPath,
	mode: 'history',
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		};
	}
	// scrollBehavior,
	// scrollBehavior2,
})

router.beforeEach(async (to, from, next) => {

	// if (to.path) {
	// 	if (window._hmt) {
	// 		window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
	// 	}
	// }
	
	
	var str = window.location.href
	// console.log(str.substring(41, str.length))
	// if (the_id == "112418") {
	if (str.substring(26, 27) === "#") {
		// next({
		// 	path: '/product',
		// 	query: {
		// 		id: to.query.id
		// 	}
		// })
		var the_id = str.substring(39, str.length)
		window.location.href = "https://www.otopchina.com/product?id=" + the_id
	} else {
		next()
	}

});

export default router
